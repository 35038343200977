var site = {
	rate : 500,
	prefix: ( window.location.hostname.indexOf('madebyreformat.co.uk') >= 0) ? '/maison-iom' : ''
};



(function($){

	new WOW().init();

	$('.mobile-toggle').click(function(){
		$('.main-header-nav, .mobile-toggle').toggleClass('toggled');
	});
	
})(jQuery);